import React from 'react'
import Loading from '../../baseComponents/Loading'
import HeaderWithBack from '../../baseComponents/HeaderWithBack'
import Grid from '../../baseComponents/Grid'
import NotFound from '../NotFoundScreen'
import { Link } from 'react-router-dom'

import { getUrlByType, getUrl } from '../../utils/routesDefinition'
import { pesProgramResources } from '../../services/pageResources'
import { connect } from 'react-redux'

class PESListScreen extends React.Component {

  state = {
    title: '',
    items: [],
    guests: [],
    year: new Date().getFullYear(),
    isLoading: true,
    isLoadingEnd: false,
    activeParticipants: false,
    years: [],
    error: null,
    type: ''
  }

  onGoBack = () => {
    const { id } = this.props.match.params
    localStorage.setItem('lastSection', JSON.stringify({
      type: 'pes',
      section: id
    }))
    localStorage.removeItem('pes-year')
    this.props.history.push(getUrl('pes'))
  }

  componentDidMount() {
    const { id } = this.props.match.params
    pesProgramResources()
      .then(program => {
        let items = [], guests = [], itemYears = [],
          title = '',
          years = {}, year = new Date().getFullYear()
        switch (id) {
          case 'participantes': {
            let participants = program.data.participants
            title = participants.title
            items = participants.items
              .map(p => ({ ...p, title: p.person, person: '', date: p.year }))
            itemYears = items
            break
          }
          case 'equipo-docente': {
            let tutors = program.data.main_tutors
            guests = program.data.main_guests.items || []
            title = tutors.title
            items = tutors.items.map(p => ({ ...p, title: p.person, person: '' }))
            guests = guests.map(p => ({ ...p, title: p.person, person: '' }))
            itemYears = [...items, ...guests]
            break
          }
          case 'cuarto-de-proyectos': {
            let projects = program.data.projects_room
            title = projects.title
            items = projects.items.map(p => ({ ...p, person: p.fullname }))
            itemYears = items
            break
          }
          case 'proyecto-final': {
            let finalProject = program.data.expositions
            title = finalProject.title
            items = finalProject.items
            itemYears = items
            break
          }
          default: return this.setState({ error: new Error('Not found'), isLoadingEnd: true, isLoading: false, type: id })
        }

        // Set year in case property does not exists
        for (let index = 0; index < items.length; index++) {
          let item = items[index]
          let year = item.year
            ? item.year
            : new Date(item.startDate).getFullYear()
          if (year) {
            items[index].year = year
          }
        }

        // to get a complete list of years if guests exists
        for (let item of itemYears) {
          let year = item.year
            ? item.year
            : new Date(item.startDate).getFullYear()
          if (year) years[year] = year
        }

        years = Object.keys(years).sort((a, b) => b - a)
        year = years[0]

        if (localStorage.getItem('pes-year')) {
          year = Number(localStorage.getItem('pes-year'))
        }

        this.setState({
          year,
          years,
          items,
          guests,
          title,
          type: id,
          isLoadingEnd: true,
        }, () => {
          this.setState({
            isLoading: false,
          })
        })
      })
  }

  render() {
    const { error, title, items, guests, year, years, isLoading, isLoadingEnd, activeParticipants } = this.state
    const { lang } = this.props.appState
    if (isLoading) return <Loading isOut={isLoadingEnd} />

    if (error) return <NotFound />

    let guestList = guests
      .filter(p => Number(p.year) === Number(year))

    return <div id='pes' className='header-participants'>
      <HeaderWithBack title={title} onGoBack={this.onGoBack} />
      <div id="main" style={{ marginTop: `${0}px` }} >
        <Grid fullmode>
          <div values={{ x: 0, y: 0, w: this.props.appState.item, h: 2, fullMode: true }} />
          <div className='content-block' values={{ x: 0, y: 0, w: this.props.appState.item, h: 2, fullMode: true }}>

            <div className='item-button-width-end item-button-border centered' style={{ height: this.props.appState.itemSize }}>
              <div className='year-selector'>
                <div className='year-selector-data' onClick={() => { this.setState(prevState => ({ activeParticipants: !prevState.activeParticipants })) }}>
                  <p>{year}</p>
                  <span className={`icon-search-05 ${activeParticipants ? 'active' : ''}`} />
                </div>
                {
                  activeParticipants
                    ? <div className='year-options' style={{ background: this.props.appState.colorTheme.base, height: this.props.appState.itemSize * 1.25 }}>
                      {years.map(y => <p onClick={e => {
                        e.stopPropagation()
                        localStorage.setItem('pes-year', `${y}`)
                        this.setState({ year: Number(y), activeParticipants: false, })
                      }} key={y}>{y}</p>)}
                    </div>
                    : null
                }
              </div>
            </div>

            {
              items
                .filter(p => Number(p.year) === Number(year))
                .map((p, index) => <div key={index} className='item-button-width-end item-button-border' style={{ height: this.props.appState.itemSize }}>
                  <Link to={`${getUrlByType(p.objectType)}?id=${p.id}`} data-title={p.title} className='item item-button item-info'>
                    <div>
                      <h2>{p.title}</h2>
                      <h4>{p.person}</h4>
                    </div>
                    <div className={`item-action-button`}>
                      <p className='font_regular'>{p.date}</p>
                      <span className="icon-search-05"></span>
                    </div>
                  </Link>
                </div>)
            }
            {
              Boolean(guestList.length) &&
              <div style={{ width: '100%' }}>
                <div className='item-button guest_block' style={{ width: '100%', height: this.props.appState.itemSize }}>
                  <h2 className='header-title'>{lang === 'es' ? 'Invitados' : 'Guests'}</h2>
                </div>
                <div className='content-block'>
                  {

                    guestList.map((p, index) => <div key={index} className='item-button-width-end item-button-border' style={{ height: this.props.appState.itemSize }}>
                      <Link to={`${getUrlByType(p.objectType)}?id=${p.id}`} data-title={p.title} className='item item-button item-info'>
                        <div>
                          <h2>{p.title}</h2>
                          <h4>{p.person}</h4>
                        </div>
                        <div className={`item-action-button`}>
                          <p className='font_regular'>{p.date}</p>
                          <span className="icon-search-05"></span>
                        </div>
                      </Link>
                    </div>)
                  }
                </div>
              </div>
            }
          </div>
        </Grid>
      </div >
    </div >
  }
}

const mapStateToProps = state => ({
  appState: state.appState
})

export default connect(mapStateToProps)(PESListScreen)