import React from 'react'
import Video from './Video'
import PDFVisor from './PDFVisor'
import { Link } from 'react-router-dom'
import logo from '../../../assets/img/logo.png'
import { connect } from 'react-redux'
import backgroundLogo from '../../../assets/img/patron_noimage.png'

class Container extends React.Component {

  state = {
    active: null,
    currentIndex: 0,
    files: [],
    loading: true,
  }

  componentType = (url) => {
    if (typeof url !== 'string') return 'url'
    if (url.search('www.youtube.com/watch') !== -1 || url.search('youtu.be') !== -1) return 'video'
    if (url.search('.pdf') !== -1) return 'pdf'
    return 'image'
  }

  renderComponent = url => {
    let { resources } = this.props
    if (typeof url !== 'string') return url
    if (url.search('www.youtube.com/watch') !== -1 || url.search('youtu.be') !== -1) return <Video {...this.props} url={url} />
    if (url.search('.pdf') !== -1) return <PDFVisor {...this.props} url={url} />
    return <div className='background img-padding' style={{ background: `url("${url}")` }} >
      {
        Boolean(resources.archive_policy) &&
        <p className='background-label'>Imágenes cortesía del artista</p>
      }
    </div>
  }

  onLoad = () => {
    this.setState({ loading: false })
  }

  setElement = value => {
    let { currentIndex, files } = this.state
      , index = currentIndex + value < 0 ? files.length - 1 : currentIndex + value
    index = files.length - 1 < index ? 0 : index

    this.setState({ currentIndex: index, active: files[index], loading: true })
  }

  componentDidMount() {
    let { resources, userAuth } = this.props
      , files = []

    if (resources.type === 'object') {
      let url = resources.public_video
      if (userAuth.id && resources.private_video) url = userAuth.id ? resources.private_video : resources.public_video
      if (typeof url === 'string' && (url.search('www.youtube.com/watch') !== -1 || url.search('youtu.be') !== -1)) files.push(url)
    }
    files = [...files, ...resources.banner]
    this.setState({ files, active: files[0] })
  }

  render() {
    let { appState, resources, type } = this.props
      , { active } = this.state
      , color = appState.colorThemeString

    color = (color === 'theme-2' || color === 'theme-4') ? appState.colorTheme.level2 : appState.colorTheme.level1

    if (appState.item === 2) return <div id='content-container' className='container'>
      <div className='header' style={{ height: (appState.headerItemSize), background: appState.colorTheme.secondary }}>
        <div className='centered-logo border-right' style={{ minWidth: appState.headerItemSize }}>
          <Link to='/' className='logo-container'>
            <img src={logo} className='logo' alt='logo' />
          </Link>
        </div>
        <div className='centered-logo title' style={{ minWidth: appState.headerItemSize * 2 }}>
          <div>
            <h2>{resources.objectTitle}</h2>
            {type === 'person' && resources.contentTwo ? <h3 style={{ textAlign: 'right' }}>{resources.contentTwo}, {resources.contentOne}</h3> : null}
          </div>
        </div>
        <div className='centered-logo border-left' style={{ minWidth: appState.headerItemSize }}>
          <span onClick={() => this.props.history.goBack()} className="icon-search-04" />
        </div>
      </div>
      <div style={{ width: '100%', maxHeight: appState.itemSize * 2, height: appState.itemSize * 2, overflow: 'hidden', background: `url("${backgroundLogo}")` }}>
        {this.renderComponent(active)}
      </div>
      <div className='controls' style={{ background: color, height: appState.itemSize - appState.headerItemSize }}>
        <div className='controls-container'>
          <div className='controls-buttons'>
            {
              this.state.files.length > 1
                ? <React.Fragment>
                  <span onClick={() => this.setElement(-1)} className="icon-search-05 left-arrow"></span>
                  <span onClick={() => this.setElement(1)} className="icon-search-05"></span>
                </React.Fragment>
                : <div style={{ width: 20 }} />
            }
          </div>
          <div className='controls-social'>
            {
              this.componentType(active) === 'image' && Boolean(resources.archive_policy) &&
              <p className='background-label'>Imágenes cortesía del artista</p>
            }
            {
              /*             <span className="icon-search-08"></span>
                          <span className="icon-search-08"></span>
                          <span className="icon-search-08"></span> */
            }
          </div>
        </div>
      </div>
    </div>

    if (appState.item === 4) return <div id='content-container' className='container'>
      <div style={{ height: (appState.itemSize * 3), }}>
        <div className='header' style={{ height: (appState.itemSize), background: appState.colorTheme.secondary }}>
          <div className='centered-logo border-right' style={{ width: appState.itemSize }}>
            <Link to='/' className='logo-container'>
              <img src={logo} className='logo' alt='logo' />
            </Link>
          </div>
          <div className='centered-logo title ' style={{ width: appState.itemSize }}>
            <div>
              <h2>{resources.objectTitle}</h2>
              {type === 'person' && resources.contentTwo ? <h3 style={{ textAlign: 'right' }}>{resources.contentTwo}, {resources.contentOne}</h3> : null}
            </div>
          </div>
        </div>
        <div style={{ width: '100%', maxHeight: appState.itemSize * 2, height: appState.itemSize * 2, overflow: 'hidden', background: `url("${backgroundLogo}")` }}>
          {this.renderComponent(active)}
        </div>
      </div>
      <div className='controls' style={{ background: color }}>
        <div className='controls-container'>
          <div className='controls-buttons'>
            {
              this.state.files.length > 1
                ? <React.Fragment>
                  <span onClick={() => this.setElement(-1)} className="icon-search-05 left-arrow"></span>
                  <span onClick={() => this.setElement(1)} className="icon-search-05"></span>
                </React.Fragment>
                : <div style={{ width: 20 }} />
            }
          </div>
          <div className='controls-social'>
            {
              this.componentType(active) === 'image' && Boolean(resources.archive_policy) &&
              <p className='background-label'>Imágenes cortesía del artista</p>
            }

            {
              /*             <span className="icon-search-08"></span>
                          <span className="icon-search-08"></span>
                          <span className="icon-search-08"></span> */
            }
          </div>
        </div>
      </div>
    </div>

    return <div id='content-container' className='container'>
      <div>
        <div className='header' style={{ height: (appState.itemSize), background: appState.colorTheme.secondary }}>
          <div className='centered-logo border-right' style={{ width: appState.itemSize }}>
            <Link to='/' className='logo-container'>
              <img src={logo} className='logo' alt='logo' />
            </Link>
          </div>
          <div className={`object-header centered-logo title border-left ${resources.objectTitle.length > 33 ? 'small-title' : ''}`} style={{ width: appState.itemSize * 2 }}>
            <div>
              <h2>{resources.objectTitle}</h2>
              {type === 'person' && resources.contentTwo ? <h3 style={{ textAlign: 'right' }}>{resources.contentTwo}, {resources.contentOne}</h3> : null}
            </div>
          </div>
        </div>
        <div className='background' style={{ width: '100%', maxHeight: appState.itemSize * 1.75, height: appState.itemSize * 1.7, overflow: 'hidden', background: `url("${backgroundLogo}")` }}>
          {this.renderComponent(active)}
        </div>
      </div>
      <div className='controls' style={{ background: color }}>
        <div className='controls-container'>
          <div className='controls-buttons'>
            {
              this.state.files.length > 1
                ? <React.Fragment>
                  <span onClick={() => this.setElement(-1)} className="icon-search-05 left-arrow"></span>
                  <span onClick={() => this.setElement(1)} className="icon-search-05"></span>
                </React.Fragment>
                : <div style={{ width: 20 }} />
            }
          </div>
          <div className='controls-social'>
            {
              this.componentType(active) === 'image' && Boolean(resources.archive_policy) &&
              <p className='background-label'>Imágenes cortesía del artista</p>
            }
            {
              /*             <span className="icon-search-08"></span>
                          <span className="icon-search-08"></span>
                          <span className="icon-search-08"></span> */
            }
          </div>
        </div>
      </div>
    </div>
  }
}

const mapStateToPros = state => ({
  userAuth: state.userAuth
})

export default connect(mapStateToPros)(Container)