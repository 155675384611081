import React from 'react'
import { Route, Switch, withRouter, Redirect } from 'react-router-dom'
import { getUrl } from './utils/routesDefinition'
import { connect } from 'react-redux'
import { setItemSize, setItems, setHeaderItemSize, setHeaderItems, setMenuStatus, setLang } from './redux/actions/appState'
import getTexts from './services/langTexts'
import { Link } from 'react-router-dom'
import { authUser, resetAuth } from './redux/actions/userAuth'
import { getFavObjects, getFavPersons } from './services/auth'

import './App.css'

import HomeScreen from './screens/HomeScreen'
import SUMMERScreen from './screens/SUMMERScreen'
import SUMMERAboutScreen from './screens/SUMMERAboutScreen'
import SUMMERListScreen from './screens/SummerListScreen'
import PESScreen from './screens/PESScreen'
import PESAboutScreen from './screens/PESAboutScreen'
import PESListScreen from './screens/PESListScreen'
import ResidenceScreen from './screens/ResidenceScreen'
import RegisterScreen from './screens/RegisterScreen'
import ObjectScreen from './screens/ObjectScreen'
import ObjectsScreen from './screens/ObjectsScreen'
import WednesdayScreen from './screens/WednesdayScreen'
import CalendarScreen from './screens/CalendarScreen'
import AboutScreen from './screens/AboutScreen'
import LoginScreen from './screens/LoginScreen'
import MyAccount from './screens/MyAccount'
import PwdRecovery from './screens/PwdRecovery'
import PrivacyNotice from './screens/PrivacyNotice'

class App extends React.Component {

  state = {
    showLoginOptions: false,
    active: false
  }

  onResize = () => {
    let headerHeight = document.getElementsByTagName('BODY')[0].offsetWidth - 4
      , headerSize = headerHeight > 1024 ? 6 : 4
      , rowHeight = document.getElementsByTagName('BODY')[0].offsetWidth - 4
      , rowSize = rowHeight > 1024 ? 6 : rowHeight > 768 ? 4 : 2

    headerHeight = Math.floor(headerHeight / headerSize)
    rowHeight = Math.floor(rowHeight / rowSize)

    this.props.dispatch(setHeaderItemSize(headerHeight))
    this.props.dispatch(setItemSize(rowHeight))
    this.props.dispatch(setItems(rowSize))
    this.props.dispatch(setHeaderItems(headerSize))
  }


  getScrollbarWidth = () => {

    // Creating invisible container
    const outer = document.createElement('div');
    outer.style.visibility = 'hidden';
    outer.style.overflow = 'scroll'; // forcing scrollbar to appear
    outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps
    document.body.appendChild(outer);

    // Creating inner element and placing it in the container
    const inner = document.createElement('div');
    outer.appendChild(inner);

    // Calculating difference between container's full width and the child width
    const scrollbarWidth = (outer.offsetWidth - inner.offsetWidth);

    // Removing temporary elements from the DOM
    outer.parentNode.removeChild(outer);

    return scrollbarWidth;

  }


  setMenuChange = () => {
    this.props.dispatch(setMenuStatus(!this.props.appState.isMenuOpen))
  }

  setMenuVisible = () => {
    this.setState(prevState => ({ showLoginOptions: !prevState.showLoginOptions }))
  }

  setLang = lang => {
    let currentLang = this.props.appState.lang
    if (currentLang !== lang) {
      localStorage.setItem('lang', lang)
      this.props.dispatch(setLang(lang))
    }
  }

  onMouseHOverIn = () => {
    this.setState({ showLoginOptions: true })
  }

  onMouseHOverOut = () => {
    this.setState({ showLoginOptions: false })
  }

  setActive = () => this.setState({ active: true })

  onSetInactive = () => this.setState({ active: false })

  onLogOut = () => {
    localStorage.removeItem('0mZTFbxo1u')
    this.props.dispatch(resetAuth())
  }

  onSetPage = link => {
    this.props.dispatch(setMenuStatus(false))
    setTimeout(() => {
      if (link !== this.props.location.pathname) this.props.history.push(link)
    }, 900)
  }

  async componentDidMount() {
    let user = localStorage.getItem('0mZTFbxo1u')
      , lang = localStorage.getItem('lang')
    if (user !== null) {
      user = JSON.parse(atob(user))
      this.props.dispatch(authUser(user))
      await getFavObjects()
      await getFavPersons()
    }
    if (typeof lang === 'string') this.props.dispatch(setLang(lang))
    document.getElementsByTagName('HTML')[0].style.background = this.props.appState.colorTheme.base
    document.getElementsByTagName('BODY')[0].style.background = this.props.appState.colorTheme.base
    this.onResize()
    window.addEventListener('resize', this.onResize)

    this.historyListener = this.props.history.listen((location, action) => {
      let lastPage = localStorage.getItem('lastSection')
      if (Boolean(lastPage)) {
        lastPage = JSON.parse(lastPage)
        let url = getUrl(lastPage.type)
        if (url !== location.pathname) localStorage.removeItem('lastSection')
      }
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.appState.colorTheme.base !== nextProps.appState.colorTheme.base) {
      document.getElementsByTagName('BODY')[0].style.background = nextProps.appState.colorTheme.base
    }
    if ((this.props.location.pathname !== nextProps.location.pathname) && this.props.appState.isMenuOpen) this.setMenuChange()
  }

  componentWillMount() {
    document.getElementsByTagName('HTML')[0].style.background = this.props.appState.colorTheme.base
  }

  componentWillUnmount() {
    this.historyListener()
    window.removeEventListener('resize', this.onResize)
  }


  render() {
    let { itemSize, colorTheme, lang } = this.props.appState
      , { user_token } = this.props.userAuth
      , langTexts = getTexts('menu', lang)
      , addrText = getTexts('address', lang)

    document.getElementsByTagName('BODY')[0].style.overflow = this.props.appState.isMenuOpen ? 'hidden' : 'auto'

    itemSize = this.props.appState.item === 6 ? (itemSize * 4) + 4 : (itemSize * this.props.appState.item) + 4

    return <React.Fragment>
      <Switch location={this.props.location}>
        <Route path='/manager-cms/:id' component={() => { window.location.reload(); return }} />
        <Route exact path={getUrl('home')} component={HomeScreen} />
        <Route exact path={'/soma-summer/soma-summer'} component={() => <Redirect to={getUrl('summer')} />} />
        <Route exact path={getUrl('privacy')} component={PrivacyNotice} />
        <Route exact path={getUrl('summer')} component={SUMMERScreen} />
        <Route exact path={`${getUrl('summer')}/:id`} component={SUMMERListScreen} />
        <Route exact path={getUrl('summer-about')} component={SUMMERAboutScreen} />
        <Route exact path={getUrl('pes')} component={PESScreen} />
        <Route exact path={`${getUrl('pes')}/:id`} component={PESListScreen} />
        <Route exact path={getUrl('pes-about')} component={PESAboutScreen} />
        <Route exact path={getUrl('wednesday')} component={WednesdayScreen} />
        <Route exact path={getUrl('residence')} component={ResidenceScreen} />
        <Route exact path={getUrl('objects')} component={ObjectsScreen} />
        <Route exact path={getUrl('objectsPerson')} component={ObjectScreen} />
        <Route exact path={getUrl('objectsObject')} component={ObjectScreen} />
        <Route exact path={getUrl('register')} component={user_token !== null ? MyAccount : RegisterScreen} />
        <Route exact path={getUrl('calendar')} component={CalendarScreen} />
        <Route exact path={getUrl('about')} component={AboutScreen} />
        <Route exact path={getUrl('account')} component={user_token !== null ? MyAccount : LoginScreen} />
        <Route exact path={`${getUrl('pwd_recovery')}/:id`} component={PwdRecovery} />
        {/* <Route exact path='/test' component={TestScreen} /> */}
      </Switch>
      <div id='menu' onClick={this.setMenuChange} className={this.props.appState.isMenuOpen ? `menu-active` : `menu-inactive`}>
        <div id='sideMenu' onClick={e => e.stopPropagation()} style={{ width: `${itemSize}px`, backgroundColor: colorTheme.base }}>
          <div id='menuContainer'>
            <div id='menuItems'>
              <div id='navigation'>
                <a onClick={() => this.onSetPage(getUrl('home'))}>{langTexts.home}</a>
                <div>
                  <p className={`${this.state.active ? 'active_hover' : ''}`}>{langTexts.lecture}</p>
                  {
                    lang === 'en'
                      ? <div className={`lectures ${lang === 'en' ? 'lang-fix' : ''}`} style={{ lineHeight: '5vw', justifyContent: lang === 'en' ? 'flex-start' : 'justify-content' }}>
                        <div style={{ marginRight: '3vw' }}>
                          <a onMouseEnter={this.setActive} onMouseLeave={this.onSetInactive} onClick={() => this.onSetPage(getUrl('pes'))}>{langTexts.pes}</a>
                          <a onMouseEnter={this.setActive} onMouseLeave={this.onSetInactive} onClick={() => this.onSetPage(getUrl('wednesday'))}>{langTexts.wednesday}</a>
                        </div>
                        <div>
                          <a onMouseEnter={this.setActive} onMouseLeave={this.onSetInactive} onClick={() => this.onSetPage(getUrl('summer'))}>{langTexts.summer}</a>
                          <a onMouseEnter={this.setActive} onMouseLeave={this.onSetInactive} onClick={() => this.onSetPage(getUrl('residence'))}>{langTexts.residence}</a>
                        </div>
                      </div>
                      : <div className={`lectures ${lang === 'en' ? 'lang-fix' : ''}`} >
                        <a onMouseEnter={this.setActive} onMouseLeave={this.onSetInactive} onClick={() => this.onSetPage(getUrl('pes'))}>{langTexts.pes}</a>
                        <a onMouseEnter={this.setActive} onMouseLeave={this.onSetInactive} onClick={() => this.onSetPage(getUrl('wednesday'))}>{langTexts.wednesday}</a>
                        <a onMouseEnter={this.setActive} onMouseLeave={this.onSetInactive} onClick={() => this.onSetPage(getUrl('summer'))}>{langTexts.summer}</a>
                        <a onMouseEnter={this.setActive} onMouseLeave={this.onSetInactive} onClick={() => this.onSetPage(getUrl('residence'))}>{langTexts.residence}</a>
                      </div>
                  }
                </div>
                <a onClick={() => this.onSetPage(getUrl('objects'))}>{langTexts.objects}</a>
                <a onClick={() => this.onSetPage(getUrl('calendar'))}>{langTexts.calendar}</a>
                <a onClick={() => this.onSetPage(getUrl('about'))}>{langTexts.about}</a>
                <a style={{ marginTop: '1vw' }} className='lectures' onClick={() => this.onSetPage(getUrl('privacy'))}>AVISO DE PRIVACIDAD</a>
              </div>

              <div id='sideOptions'>
                <div style={{ margin: '20px', zIndex: 10000 }} onClick={this.setMenuChange}>
                  <span className="icon-search-04"></span>
                </div>


                {
                  this.props.appState.item !== 2
                    ? <div className='login-container-hover' onMouseEnter={this.onMouseHOverIn} onMouseLeave={this.onMouseHOverOut}>
                      {
                        this.state.showLoginOptions
                          ? <div className='login-menu'>
                            {
                              this.props.userAuth.user_token
                                ? <React.Fragment>
                                  <Link to={getUrl('account')}>VER PERFIL</Link>
                                  <p onClick={this.onLogOut}>CERRAR SESIÓN</p>
                                </React.Fragment>
                                : <React.Fragment>
                                  <Link to={getUrl('account')}>INICIAR SESIÓN</Link>
                                  <Link to={getUrl('register')}>REGISTRARME</Link>
                                </React.Fragment>
                            }
                          </div>
                          : <div className='user-btn' style={{ margin: '20px', zIndex: 10000 }} onClick={this.setMenuVisible}>
                            <span className="icon-search-06"></span>
                          </div>
                      }
                    </div>
                    : null
                }
              </div>
            </div>
            {
              this.props.appState.item === 2
                ? <div className='login-menu'>
                  {
                    this.props.userAuth.user_token
                      ? <React.Fragment>
                        <Link to={getUrl('account')}>VER PERFIL</Link>
                        <p onClick={this.onLogOut}>CERRAR SESIÓN</p>
                      </React.Fragment>
                      : <React.Fragment>
                        <Link to={getUrl('account')}>INICIAR SESIÓN</Link>
                        <Link to={getUrl('register')}>REGISTRARME</Link>
                      </React.Fragment>
                  }
                </div>
                : null
            }
            <div id='menuFooter'>
              <div className='footer-info-container'>
                {
                  this.props.appState.item === 2
                    ? <React.Fragment>
                      <p>{addrText.addr1} {addrText.addr2}</p>
                      <div className='footer-info'>
                        <p>+52 55 5277 4947</p>
                        <p>info@somamexico.org</p>
                      </div>
                    </React.Fragment>
                    : <React.Fragment>
                      <p>{addrText.addr1} {addrText.addr2}</p>
                      <div className='footer-info'>
                        <p>+52 55 5277 4947</p>
                        <p>info@somamexico.org</p>
                      </div>
                    </React.Fragment>
                }
              </div>
              <div className='lang-switcher'>
                <div className={lang === 'en' ? 'active' : ''} style={{ color: lang === 'en' ? colorTheme.secondary : 'black' }} onClick={() => { this.setLang('en'); this.props.dispatch(setMenuStatus(false)); }}>ENGLISH</div>
                <div className={lang === 'es' ? 'active' : ''} style={{ color: lang === 'es' ? colorTheme.secondary : 'black' }} onClick={() => { this.setLang('es'); this.props.dispatch(setMenuStatus(false)); }}>ESPAÑOL</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  }
}
const mapStateToProps = state => ({
  appState: state.appState,
  userAuth: state.userAuth,
})

export default connect(mapStateToProps)(withRouter(App))