const template = {
  6: [
    { x: 0, y: 0, w: 6, h: 2, component: 'Slider' },
    { x: 0, y: 2, w: 6, h: 2, component: 'Description' },
    { x: 0, y: 4, w: 6, h: 2, component: 'FullDescription' },
    { x: 0, y: 6, w: 6, h: 2, component: 'Logo' },
    { x: 0, y: 8, w: 6, h: 3, component: 'History' },
    { x: 0, y: 11, w: 6, h: 10, component: 'Councils' },
    { x: 0, y: 21, w: 6, h: 2, component: 'Map' },
    { x: 0, y: 23, w: 4, h: 1, component: 'ContactProgram' },
    { x: 4, y: 23, w: 2, h: 1, component: 'Newsletter' },
  ],
  4: [
    { x: 0, y: 0, w: 4, h: 2, component: 'Slider' },
    { x: 0, y: 2, w: 4, h: 2, component: 'Description' },
    { x: 0, y: 4, w: 4, h: 3, component: 'FullDescription' },
    { x: 0, y: 7, w: 4, h: 3, component: 'Logo' },
    { x: 0, y: 10, w: 4, h: 3, component: 'History' },
    { x: 0, y: 13, w: 4, h: 9, component: 'Councils' },
    { x: 0, y: 22, w: 4, h: 2, component: 'Map' },
    { x: 0, y: 24, w: 2, h: 1, component: 'ContactProgram' },
    { x: 2, y: 24, w: 2, h: 1, component: 'Newsletter' },
  ],
  2: [
    { x: 0, y: 0, w: 2, h: 1.5, component: 'Slider' },
    { x: 0, y: 1.5, w: 2, h: 2, component: 'Description' },
    { x: 0, y: 3.5, w: 2, h: 3, component: 'FullDescription' },
    { x: 0, y: 6.5, w: 2, h: 3, component: 'Logo' },
    { x: 0, y: 9.5, w: 2, h: 4.5, component: 'History' },
    { x: 0, y: 14, w: 2, h: 16.5, component: 'Councils' },
    { x: 0, y: 30.5, w: 2, h: 1, component: 'Map' },
    { x: 0, y: 31.5, w: 1, h: 1, component: 'ContactProgram' },
    { x: 1, y: 31.5, w: 1, h: 1, component: 'Newsletter' },
  ],
  1: [
    { x: 0, y: 0, w: 1, h: 1, component: 'Shape' },
  ]
}



const getTemplate = () => {
  return template
}

export default getTemplate

/*


    { x: 0, y: 21, w: 2, h: 1, component: 'Map' },
    { x: 0, y: 22, w: 1, h: 1, component: 'ContactProgram' },
    { x: 1, y: 22, w: 1, h: 1, component: 'Newsletter' },
*/